import React, { useContext, useEffect } from 'react';
import { DataContext } from '../context/DataContext';
import '../App.css';

function ContentSection() {
  const { data, fetchData, fetchBildUrl, bilderUrls } = useContext(DataContext);

  useEffect(() => {
    fetchData('ContentSection');
    const imageKeys = ['Section-image1', 'Section-image2', 'Section-image3'];
    imageKeys.forEach(key => fetchBildUrl(key));
  }, [fetchData, fetchBildUrl]);
  const contentSectionBereich = 'ContentSection';
  const contentSectionData = data['ContentSection'] || []; // Stellen Sie sicher, dass dies ein Array ist
  const headings = contentSectionData.filter(item => item.bereich === contentSectionBereich && item.text.startsWith('heading'));
  const paragraphs = contentSectionData.filter(item => item.bereich === contentSectionBereich && item.text.startsWith('paragraph2'));

  return (
    <section className="section-3 wf-section">
      {headings.map((heading, index) => {
        const paragraph = paragraphs[index] ? paragraphs[index].inhalt : '...';
        const imageKey = `Section-image${index + 1}`;
        const imageUrl = bilderUrls[imageKey]; // Hol die Bild-URL mit dem Schlüssel
        const isEven = index % 2 === 0; // Überprüfen, ob der Index gerade ist

        return (
          <div key={heading.id} className="lynx-content">
            <div className={`w-layout-grid lynx-grid-${isEven ? 'image-right' : 'image-left'}`}>
              {isEven ? (
                <>
                  <div className="lynx-block-left">
                    <h2 className="lynx-heading">{heading.inhalt}</h2>
                    <p className="lynx-paragraph">{paragraph}</p>
                  </div>
                  {imageUrl && (
                    <div className="lynx-block-image">
                      <img src={imageUrl} alt={`Section ${index + 1}`} className="lynx-image" />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {imageUrl && (
                    <div className="lynx-block-image">
                      <img src={imageUrl} alt={`Section ${index + 1}`} className="lynx-image" />
                    </div>
                  )}
                  <div className="lynx-block-left">
                    <h2 className="lynx-heading">{heading.inhalt}</h2>
                    <p className="lynx-paragraph">{paragraph}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      })}
    </section>
  );
}

export default ContentSection;





