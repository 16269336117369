import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../context/DataContext';
import { useParams } from 'react-router-dom';
import '../App.css';
import API_URL from '../context/config';

function Personal() {
  const { personalId } = useParams();
  const { data, fetchData, fetchBildUrl, bilderUrls } = useContext(DataContext);
  const [personalData, setPersonalData] = useState({
    headers: [],
    personName: '',
    description: '',
    contacts: [],
    resumeButton: '',
  });
  const [profileImageUrl, setProfileImageUrl] = useState('');

  useEffect(() => {
    fetchData(`Personal${personalId}`);
    fetchBildUrl(`Team-image${personalId}`);
  }, [fetchData, fetchBildUrl, personalId]);

  useEffect(() => {
    const loadedPersonalData = data[`Personal${personalId}`] || [];
    const personalBereich = `Personal${personalId}`;
    let currentHeaderIndex = 0;

    const headers = loadedPersonalData
      .filter(item => item.bereich === personalBereich && item.text.startsWith('header-personal'))
      .map(header => {
        currentHeaderIndex++;
        const paragraph = loadedPersonalData.find(item => item.bereich === personalBereich && item.text === `paragraph-personal` && item.id === header.id + 1)?.inhalt;
        const listItems = loadedPersonalData.filter(item => item.bereich === personalBereich && item.text === `sichpunkte-personal${currentHeaderIndex}`);

        return { ...header, paragraph, listItems };
      });

    const personName = loadedPersonalData.find(item => item.bereich === personalBereich && item.text === 'name-personal')?.inhalt;
    const description = loadedPersonalData.find(item => item.bereich === personalBereich && item.text === 'text-personal')?.inhalt;
    const contacts = loadedPersonalData.filter(item => item.bereich === personalBereich && item.text.startsWith('Kontakt-personal'));
    const resumeButton = loadedPersonalData.find(item => item.bereich === personalBereich && item.text === 'Kontakt-button-personal')?.inhalt;

    setPersonalData({ headers, personName, description, contacts, resumeButton });
    setProfileImageUrl(bilderUrls[`Team-image${personalId}`]);
  }, [data, bilderUrls, fetchData, fetchBildUrl, personalId]);

  const apiDokumentUrl = `${API_URL}/dok/${getDokumentBezeichnung(personalId)}`;

  function getDokumentBezeichnung(id) {
    switch(id) {
      case '1': return 'IHO';
      case '2': return 'ABO';
      default: return 'default';
    }
  }

  return (
    <div className="body-3">
      <div className="section-6 main wf-section">
        <div className="content-container">
          <div className="main-info-strip-wrap">
            {personalData.headers.map((header) => {
              return (
                <div key={header.id} className="section-wrap">
                  <div className="section-name-wrap">
                    <div className="section-icon-box">
                      {/* Hier kann ein entsprechendes Icon eingefügt werden */}
                    </div>
                    <h2 className="h2">{header.inhalt}</h2>
                  </div>
                  <div className="section-content-wrap top-margin-normal">
                    {header.paragraph && <p className="paragraph top-margin-small" dangerouslySetInnerHTML={{ __html: header.paragraph }} />}
                    {header.listItems.length > 0 && (
                      <ul className="list-4">
                        {header.listItems.map(listItem => (
                          <li key={listItem.id}>
                            <div className="text-block-108">{listItem.inhalt}</div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
  
          <div className="contact-info-wrap">
            {profileImageUrl && (
              <img src={profileImageUrl} loading="lazy" alt="Profilbild" className="profile-image" />
            )}
            <div className="under-picture-info">
              {personalData.personName && <div className="name-text">{personalData.personName}</div>}
              {personalData.description && <div className="age-text">{personalData.description}</div>}
              <div className="contact-links-wrap">
                {personalData.contacts.map((contact, index) => (
                  <a key={index} href={contact.inhalt.startsWith('015') ? `tel:${contact.inhalt}` : `mailto:${contact.inhalt}`} className="contact-link w-inline-block">
                    <img src={contact.inhalt.startsWith('015') ? "images/baseline_call_white_24dp.png" : "images/baseline_email_white_24dp.png"} loading="lazy" width="20" alt="Kontakt-Icon" className="contact-icon" />
                    <div>{contact.inhalt}</div>
                  </a>
                ))}
              </div>
              {personalData.resumeButton && (
                <div className="buttons-wrap">
                  <a href={apiDokumentUrl} target="_blank" rel="noopener noreferrer" className="download-cv-button w-inline-block">
                    <div className="button-content-wrap">
                      <img src="images/baseline_file_download_white_24dp.png" loading="lazy" alt="" className="button-icon" />
                      <div>{personalData.resumeButton}</div>
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Personal;