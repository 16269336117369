import React, { useState } from 'react';
import "../contact.css";
import API_URL from '../context/config';

function Kontaktformular() {
  const [formDaten, setFormDaten] = useState({
    name: '',
    email: '',
    nachricht: '',
    honeypot: '' // Honeypot-Feld hinzugefügt
  });
  const [messageSent, setMessageSent] = useState(false);

  const handleChange = (e) => {
    setFormDaten({
      ...formDaten,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Überprüfe das Honeypot-Feld
    if (formDaten.honeypot) {
      // Es handelt sich wahrscheinlich um einen Bot
      console.log('Bot erkannt');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDaten),
      });

      if (response.ok) {
        setMessageSent(true); // Nachricht wurde gesendet
        setFormDaten({ name: '', email: '', nachricht: '' }); // Formular zurücksetzen
        setTimeout(() => setMessageSent(false), 5000); // Nachricht nach 5 Sekunden ausblenden
      } else {
        // Hier könntest du eine Fehlermeldung anzeigen
      }
    } catch (error) {
      console.error('Fehler:', error);
    }
  }

  return (
    <div className="kontaktformular">
      <h1>Kontaktieren Sie uns</h1> {/* Überschrift hinzugefügt */}
      <div className="form-container"> {/* Container für das Formular */}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
            id="name"
              type="text"
              name="name"
              value={formDaten.name}
              onChange={handleChange}
              placeholder="Name"
              required
            />
            <input
            id="emailfeld"
              type="email"
              name="email"
              value={formDaten.email}
              onChange={handleChange}
              placeholder="E-Mail"
              required
            />
          </div>
          <textarea
          id="nachrichtenfeld"
            name="nachricht"
            value={formDaten.nachricht}
            onChange={handleChange}
            placeholder="Nachricht"
            required
          />
         <div style={{ display: 'none' }}> {/* Honeypot-Feld (unsichtbar) */}
            <input
              type="text"
              name="honeypot"
              value={formDaten.honeypot}
              onChange={handleChange}
            />
          </div>
          {messageSent && <div id="message" className="success-message">Nachricht gesendet!</div>}
          <button 
          id="senden"
          type="submit" 
           disabled={!formDaten.name || !formDaten.email || !formDaten.nachricht}
           >Senden</button>
        </form>
      </div>
    </div>
  );
}

export default Kontaktformular;

