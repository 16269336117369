import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../context/DataContext';
import { Link } from 'react-router-dom';
import '../App.css';

function Team() {
  const { data, fetchData, fetchBildUrl, bilderUrls } = useContext(DataContext);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    fetchData('Team');
    fetchBildUrl('team-background-image');
  }, [fetchData, fetchBildUrl]);

  useEffect(() => {
    setBackgroundImageUrl(bilderUrls['team-background-image']);
    const TeamData = data['Team'] || [];
    const memberNames = TeamData.filter(item => item.bereich === 'Team' && item.text.startsWith('Mitglied-Name'));
    const members = memberNames.map((member, index) => {
      const name = member.inhalt;
      const rolesKey = `Mitglied-Sichpunkte-${index + 1}`;
      const roles = TeamData.filter(roleItem => roleItem.bereich === 'Team' && roleItem.text.startsWith(rolesKey)).map(role => role.inhalt.trim());
      const availabilityKey = `Mitglied-ver-${index + 1}`;
      const availability = TeamData.find(availItem => availItem.bereich === 'Team' && availItem.text === availabilityKey)?.inhalt;
      const imageKey = `Team-image${index + 1}`;
      fetchBildUrl(imageKey); // Stellen Sie sicher, dass das Bild für jedes Teammitglied geladen wird

      return { name, roles, availability, imageKey };
    });

    setTeamMembers(members);
  }, [data, bilderUrls, fetchBildUrl]);

  return (
    <section className="section-5 wf-section">
      <div className="div-block">
  {/* Hintergrundbild als normales <img> Element */}
  {backgroundImageUrl && (
    <img src={backgroundImageUrl} alt="Hintergrund" className="background-image" />
  )}
      <div className="div-block-4">
        <ol className="list-2 w-list-unstyled">
          <li><h1 className="heading">Unsere Mitarbeiter</h1></li>
          {teamMembers.map((member, index) => (
            <li key={index}>
              <div className="div-block-2">
                <div className="w-layout-layout">
                  <div className="w-layout-cell">
                    {/* Mitgliederbild als normales <img> Element anzeigen */}
                    {bilderUrls[member.imageKey] && (
                      <img src={bilderUrls[member.imageKey]} alt={`Mitglied ${index + 1}`} className="team-member-image" />
                    )}
                  </div>
                  <div className="div-block-102">
                    <div className="w-layout-cell"><div className="text-block-7">{member.name}</div></div>
                    <div className="w-layout-cell">
                      <ul className="list-3">
                        {member.roles.map((role, roleIndex) => <li key={roleIndex}><div>{role}</div></li>)}
                      </ul>
                    </div>
                    <div className="w-layout-cell"><div className="text-block-11">{member.availability}</div></div>
                    <div className="w-layout-cell">
                    <Link to={`/personal/${index + 1}`} className="button-2">Zum Profil</Link>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
      </div>
    </section>
  );
}

export default Team;



